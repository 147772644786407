import { API_BASE_URL, ACCESS_TOKEN } from "../constants";

const request = options => {
  const headers = new Headers({
    "Content-Type": "application/json",
  });

  if (localStorage.getItem(ACCESS_TOKEN)) {
    headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem(ACCESS_TOKEN)
    );
  }

  const defaults = { headers: headers };
  options = Object.assign({}, defaults, options);

  return fetch(options.url, options).then(response =>
    response.json().then(json => {
      if (!response.ok) {
        return Promise.reject(json);
      }
      return json;
    })
  );
};

export function editPassword(passwordData) {
  return request({
    url: API_BASE_URL + "/account/updatePassword",
    method: "PUT",
    body: JSON.stringify(passwordData),
  });
}

export function verifyEmail(token) {
  return request({
    url: API_BASE_URL + "/auth/verifyEmail/" + token,
    method: "PUT",
  });
}

export function resetPassword(forgotPasswordData) {
  return request({
    url: API_BASE_URL + "/auth/forgetPassword",
    method: "PUT",
    body: JSON.stringify(forgotPasswordData),
  });
}

export function checkPasswordResetTokenExpired(token) {
  return request({
    url: API_BASE_URL + "/auth/confirmReset/" + token,
    method: "PUT",
  });
}

export function resetNewPassword(passwordData, token) {
  return request({
    url: API_BASE_URL + "/auth/resetPassword/" + token,
    method: "PUT",
    body: JSON.stringify(passwordData),
  });
}

export function getUserWallets() {
  return request({
    url: API_BASE_URL + "/wallet/findByUser",
    method: "GET",
  });
}

export function createWallet(walletData) {
  return request({
    url: API_BASE_URL + "/wallet/addWallet",
    method: "POST",
    body: JSON.stringify(walletData),
  });
}

export function editWallet(walletId, walletName) {
  return request({
    url: API_BASE_URL + "/wallet/editName/" + walletId,
    method: "PUT",
    body: JSON.stringify({ name: walletName }),
  });
}

export function getWallet(walletId) {
  return request({
    url: API_BASE_URL + "/wallet/findWallet/" + walletId,
    method: "GET",
  });
}

export function deleteWallet(walletId) {
  return request({
    url: API_BASE_URL + "/wallet/deleteWallet/" + walletId,
    method: "DELETE",
  });
}

export function getExpensesCategory() {
  return request({
    url: API_BASE_URL + "/category/getExpenses",
    method: "GET",
  });
}

export function getIncomeCategory() {
  return request({
    url: API_BASE_URL + "/category/getIncome",
    method: "GET",
  });
}

export function getExpenseTransactions(walletId) {
  return request({
    url: API_BASE_URL + "/expenses/findByWalletId/" + walletId,
    method: "GET",
  });
}

export function getExpenseById(expenseId) {
  return request({
    url: API_BASE_URL + "/expenses/findById/" + expenseId,
    method: "GET",
  });
}

export function getExpenseStatisticByWalletId(walletId) {
  return request({
    url: API_BASE_URL + "/expenses/getStatistic/" + walletId,
    method: "GET",
  });
}

export function getIncomeById(incomeId) {
  return request({
    url: API_BASE_URL + "/income/findById/" + incomeId,
    method: "GET",
  });
}

export function getIncomeTransactions(walletId) {
  return request({
    url: API_BASE_URL + "/income/findByWalletId/" + walletId,
    method: "GET",
  });
}

export function createExpenseTransaction(
  transactionData,
  categoryName,
  walletId
) {
  return request({
    url:
      API_BASE_URL + "/expenses/addExpenses/" + categoryName + "/" + walletId,
    method: "POST",
    body: JSON.stringify(transactionData),
  });
}

export function createIncomeTransaction(
  transactionData,
  categoryName,
  walletId
) {
  return request({
    url: API_BASE_URL + "/income/addIncome/" + categoryName + "/" + walletId,
    method: "POST",
    body: JSON.stringify(transactionData),
  });
}

export function editExpenseTransaction(
  transactionData,
  categoryName,
  expenseId
) {
  return request({
    url:
      API_BASE_URL + "/expenses/editExpenses/" + categoryName + "/" + expenseId,
    method: "PUT",
    body: JSON.stringify(transactionData),
  });
}

export function editIncomeTransaction(transactionData, categoryName, incomeId) {
  return request({
    url: API_BASE_URL + "/income/editIncome/" + categoryName + "/" + incomeId,
    method: "PUT",
    body: JSON.stringify(transactionData),
  });
}

export function deleteExpenseTransaction(transactionId) {
  return request({
    url: API_BASE_URL + "/expenses/deleteExpenses/" + transactionId,
    method: "DELETE",
  });
}

export function deleteIncomeTransaction(transactionId) {
  return request({
    url: API_BASE_URL + "/income/deleteIncome/" + transactionId,
    method: "DELETE",
  });
}

export function createThread(threadData) {
  return request({
    url: API_BASE_URL + "/forumpost/addPost",
    method: "POST",
    body: JSON.stringify(threadData),
  });
}

export function editThread(threadData, threadId) {
  return request({
    url: API_BASE_URL + "/forumpost/editPost/" + threadId,
    method: "PUT",
    body: JSON.stringify(threadData),
  });
}

export function getThreads() {
  return request({
    url: API_BASE_URL + "/forumpost/all",
    method: "GET",
  });
}

export function getThreadById(threadId) {
  return request({
    url: API_BASE_URL + "/forumpost/findById/" + threadId,
    method: "GET",
  });
}

export function deleteThread(threadId) {
  return request({
    url: API_BASE_URL + "/forumpost/deletePost/" + threadId,
    method: "DELETE",
  });
}

export function getCommentById(commentId) {
  return request({
    url: API_BASE_URL + "/forumcomment/findById/" + commentId,
    method: "GET",
  });
}

export function getCommentByThreadId(threadId) {
  return request({
    url: API_BASE_URL + "/forumcomment/findByPostId/" + threadId,
    method: "GET",
  });
}

export function addComment(commentData, threadId) {
  return request({
    url: API_BASE_URL + "/forumcomment/addComment/" + threadId,
    method: "POST",
    body: JSON.stringify(commentData),
  });
}

export function editComment(commentData, commentId) {
  return request({
    url: API_BASE_URL + "/forumcomment/editComment/" + commentId,
    method: "PUT",
    body: JSON.stringify(commentData),
  });
}

export function deleteComment(commentId) {
  return request({
    url: API_BASE_URL + "/forumcomment/deleteComment/" + commentId,
    method: "DELETE",
  });
}

export function getCaptcha() {
  return request({
    url: API_BASE_URL + "/auth/getCaptcha",
    method: "GET",
  });
}

export function login(loginRequest) {
  return request({
    url: API_BASE_URL + "/auth/signin",
    method: "POST",
    body: JSON.stringify(loginRequest),
  });
}

export function signup(signupRequest) {
  return request({
    url: API_BASE_URL + "/auth/signup",
    method: "POST",
    body: JSON.stringify(signupRequest),
  });
}

export function checkUsernameAvailability(username) {
  return request({
    url: API_BASE_URL + "/user/checkUsernameAvailability?username=" + username,
    method: "GET",
  });
}

export function checkEmailAvailability(email) {
  return request({
    url: API_BASE_URL + "/user/checkEmailAvailability?email=" + email,
    method: "GET",
  });
}

export function getCurrentUser() {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: API_BASE_URL + "/auth/me",
    method: "GET",
  });
}
