import {
  Table,
  Col,
  Row,
  Typography,
  Icon,
  Divider,
  Popconfirm,
  notification,
  Tabs,
} from "antd";
import moment from "moment";
import randomColor from "randomcolor";
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { Button } from "antd/lib/radio";
import { APP_NAME } from "../constants";

import {
  deleteExpenseTransaction,
  deleteIncomeTransaction,
  getExpensesCategory,
  getExpenseStatisticByWalletId,
  getExpenseTransactions,
  getIncomeTransactions,
  getWallet,
} from "../util/APIUtils";
import "./Transaction.css";
const { TabPane } = Tabs;
const { Title } = Typography;

class Transaction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expenseStatistics: [],
      wallet: {},
      expenseTransactions: [],
      incomeTransactions: [],
      categoryList: [],
      isLoading: false,
    };
    this.deleteExpenseTransactionHandler = this.deleteExpenseTransactionHandler.bind(
      this
    );
  }

  handleChange = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  loadExpenseCategory() {
    const { currentUser } = this.props;
    let promise;
    if (currentUser && currentUser.username) {
      promise = getExpensesCategory();
    }
    if (!promise) {
      return;
    }

    this.setState({
      isLoading: true,
    });
    promise
      .then(response => {
        this.setState({
          categoryList: response,
        });
      })
      .catch(error => {});
  }

  loadWallet(walletId) {
    const { currentUser } = this.props;
    let promise;

    if (currentUser && currentUser.username) {
      promise = getWallet(walletId);
    }
    if (!promise) {
      return;
    }

    this.setState({
      isLoading: true,
    });

    promise
      .then(response => {
        if (!response.message) {
          this.setState({
            wallet: response,
          });
        } else {
          notification.error({
            message: APP_NAME,
            description:
              response.message ||
              "Sorry! Something went wrong. Please try again!",
          });
          this.props.history.push("/");
        }
      })
      .catch(error => {
        if (error.status === 401) {
          this.props.handleSessionTimeout();
        } else {
          notification.error({
            message: APP_NAME,
            description: "Sorry! Something went wrong. Please try again!",
          });
          this.props.history.push("/");
        }
      });
  }

  checkLoggedIn() {
    const { currentUser } = this.props;

    if (!currentUser) {
      this.props.history.push("/login");
    }
  }

  loadExpenseTransactions(walletId) {
    const { currentUser } = this.props;
    let promise;

    if (currentUser && currentUser.username) {
      promise = getExpenseTransactions(walletId);
    }
    if (!promise) {
      return;
    }

    this.setState({
      isLoading: true,
    });

    promise
      .then(response => {
        if (!response.message) {
          this.setState({
            expenseTransactions: response,
          });
        }
      })
      .catch(error => {});
  }

  loadIncomeTransactions(walletId) {
    const { currentUser } = this.props;
    let promise;

    if (currentUser && currentUser.username) {
      promise = getIncomeTransactions(walletId);
    }
    if (!promise) {
      return;
    }

    this.setState({
      isLoading: true,
    });

    promise
      .then(response => {
        if (!response.message) {
          this.setState({
            incomeTransactions: response,
          });
        }
      })
      .catch(error => {});
  }

  loadExpenseStatistics(walletId) {
    const { currentUser } = this.props;
    let promise;

    if (currentUser && currentUser.username) {
      promise = getExpenseStatisticByWalletId(walletId);
    }
    if (!promise) {
      return;
    }

    this.setState({
      isLoading: true,
    });

    promise
      .then(response => {
        if (!response.message) {
          this.setState({
            expenseStatistics: response,
            isLoading: false,
          });
        }
      })
      .catch(error => {
        this.setState({
          isLoading: false,
        });
      });
  }

  deleteExpenseTransactionHandler(transactionId) {
    const { currentUser } = this.props;
    let promise;
    if (currentUser && currentUser.username) {
      deleteExpenseTransaction(transactionId)
        .then(response => {
          if (response.success) {
            notification.success({
              message: APP_NAME,
              description:
                "You have successfully deleted an expense transaction.",
            });
            window.location.reload();
          } else {
            notification.error({
              message: APP_NAME,
              description:
                response.message ||
                "Sorry! Something went wrong. Please try again!",
            });
          }
        })
        .catch(error => {
          notification.error({
            message: APP_NAME,
            description:
              error.message || "Sorry! Something went wrong. Please try again!",
          });
        });
    }
    if (!promise) {
      return;
    }
  }

  deleteIncomeTransactionHandler(transactionId) {
    const { currentUser } = this.props;
    let promise;
    if (currentUser && currentUser.username) {
      deleteIncomeTransaction(transactionId)
        .then(response => {
          if (response.success) {
            notification.success({
              message: APP_NAME,
              description:
                "You have successfully deleted an income transaction.",
            });
            window.location.reload();
          } else {
            notification.error({
              message: APP_NAME,
              description:
                response.message ||
                "Sorry! Something went wrong. Please try again!",
            });
          }
        })
        .catch(error => {
          notification.error({
            message: APP_NAME,
            description:
              error.message || "Sorry! Something went wrong. Please try again!",
          });
        });
    }
    if (!promise) {
      return;
    }
  }

  componentDidMount() {
    const { match } = this.props;
    const walletId = match.params.walletId;
    this.setState({
      walletId,
    });
    this.checkLoggedIn();
    this.loadWallet(walletId);
    this.loadExpenseTransactions(walletId);
    this.loadIncomeTransactions(walletId);
    this.loadExpenseCategory();
    this.loadExpenseStatistics(walletId);
  }

  render() {
    let { sortedInfo, filteredInfo, expenseStatistics } = this.state;
    sortedInfo = sortedInfo || {};
    filteredInfo = filteredInfo || {};

    const expenseColumns = [
      {
        title: "Date",
        dataIndex: "createdOn",
        key: "createdOn",
        render: createdOn => moment(createdOn).format("YYYY-MM-DD HH:mm:ss"),
        sorter: (a, b) => a.createdOn.length - b.createdOn.length,
        sortOrder: sortedInfo.columnKey === "createdOn" && sortedInfo.order,
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
        sorter: (a, b) => a.description - b.description,
        sortOrder: sortedInfo.columnKey === "description" && sortedInfo.order,
      },
      {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
        sorter: (a, b) => a.amount - b.amount,
        sortOrder: sortedInfo.columnKey === "amount" && sortedInfo.order,
      },
      {
        title: "Category",
        dataIndex: "category",
        key: "category",
        render: category => category.name,
        filters: [
          { text: "London", value: "London" },
          { text: "New York", value: "New York" },
        ],
        filteredValue: filteredInfo.category || null,
        onFilter: (value, record) => record.category.includes(value),
        sorter: (a, b) => a.category.length - b.category.length,
        sortOrder: sortedInfo.columnKey === "category" && sortedInfo.order,
      },
      {
        title: "Action",
        key: "action",
        render: (text, record) => (
          <span>
            <Link
              to={`/wallet/${this.state
                .walletId}/expense/${text.expensesId}/edit`}
            >
              Edit
            </Link>
            <Divider type="vertical" />
            <Popconfirm
              title="Are you sure？"
              okText="Yes"
              cancelText="No"
              onConfirm={() =>
                this.deleteExpenseTransactionHandler(text.expensesId)}
            >
              <a href="#">Delete</a>
            </Popconfirm>
          </span>
        ),
      },
    ];

    const incomeColumns = [
      {
        title: "Date",
        dataIndex: "createdOn",
        key: "createdOn",
        render: createdOn => moment(createdOn).format("YYYY-MM-DD HH:mm:ss"),
        sorter: (a, b) => a.createdOn.length - b.createdOn.length,
        sortOrder: sortedInfo.columnKey === "createdOn" && sortedInfo.order,
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
        sorter: (a, b) => a.description - b.description,
        sortOrder: sortedInfo.columnKey === "description" && sortedInfo.order,
      },
      {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
        sorter: (a, b) => a.amount - b.amount,
        sortOrder: sortedInfo.columnKey === "amount" && sortedInfo.order,
      },
      {
        title: "Category",
        dataIndex: "category",
        key: "category",
        render: category => category.name,
        filters: [
          { text: "London", value: "London" },
          { text: "New York", value: "New York" },
        ],
        filteredValue: filteredInfo.category || null,
        onFilter: (value, record) => record.category.includes(value),
        sorter: (a, b) => a.category.length - b.category.length,
        sortOrder: sortedInfo.columnKey === "category" && sortedInfo.order,
      },
      {
        title: "Action",
        key: "action",
        render: (text, record) => (
          <span>
            <Link
              to={`/wallet/${this.state.walletId}/income/${text.incomeId}/edit`}
            >
              Edit
            </Link>
            <Divider type="vertical" />
            <Popconfirm
              title="Are you sure？"
              okText="Yes"
              cancelText="No"
              onConfirm={() =>
                this.deleteIncomeTransactionHandler(text.incomeId)}
            >
              <a href="#">Delete</a>
            </Popconfirm>
          </span>
        ),
      },
    ];

    return (
      <div style={{ paddingTop: "36px" }}>
        <Row>
          <Title level={1}>{this.state.wallet.name}</Title>
          <Title level={4}>SGD {this.state.wallet.balance}</Title>
        </Row>
        <Row>
          <Col span={24}>
            <Title level={3}>Expenses Per Month</Title>
          </Col>
          <Col span={24}>
            <BarChart
              width={500}
              height={300}
              data={expenseStatistics}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="Name" />
              <YAxis />
              <Tooltip />
              <Legend />
              {this.state.categoryList.map((category, categoryIndex) => (
                <Bar
                  key={category.name}
                  dataKey={category.name}
                  stackId="a"
                  fill={randomColor({ luminosity: "dark", hue: "random" })}
                />
              ))}
            </BarChart>
          </Col>
        </Row>
        <Row style={{ marginTop: "36px" }}>
          <Col span={12}>
            <Title level={3}>Latest Transactions</Title>
          </Col>
          <Col span={12} style={{ textAlign: "right" }}>
            <Link to={`/wallet/${this.state.walletId}/transaction/add`}>
              <Button>
                <Icon type="plus" /> Add Transaction
              </Button>
            </Link>
          </Col>
        </Row>
        <Row>
          <Tabs defaultActiveKey="1">
            <TabPane tab="Expense" key="1">
              <Table
                rowKey="expensesId"
                columns={expenseColumns}
                dataSource={this.state.expenseTransactions}
                onChange={this.handleChange}
              />
            </TabPane>
            <TabPane tab="Income" key="2">
              <Table
                rowKey="incomeId"
                columns={incomeColumns}
                dataSource={this.state.incomeTransactions}
                onChange={this.handleChange}
              />
            </TabPane>
          </Tabs>
        </Row>
      </div>
    );
  }
}

export default withRouter(Transaction);
