import { Form, Input, Button, Icon, notification } from "antd";
import React, { Component } from "react";
import { APP_NAME } from "../../constants";
import { editPassword } from "../../util/APIUtils";

import {
  validateConfirmPassword,
  validateLoginPassword,
  validatePassword,
} from "../../util/Validators";

import "./EditPassword.css";
const FormItem = Form.Item;

class EditPassword extends Component {
  render() {
    const AntWrappedEditPasswordForm = Form.create()(EditPasswordForm);
    return (
      <div className="edit-password-container">
        <h1 className="page-title">Edit Password</h1>
        <div className="edit-password-content">
          <AntWrappedEditPasswordForm
            onLogin={this.props.onLogin}
            handleLogout={this.props.handleLogout}
            currentUser={this.props.currentUser}
          />
        </div>
      </div>
    );
  }
}

class EditPasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPassword: {
        value: "",
      },
      newPassword: {
        value: "",
      },
      confirmPassword: {
        value: "",
      },
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.isFormInvalid = this.isFormInvalid.bind(this);
  }

  handleInputChange(event, validationFun) {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;
    this.setState({
      [inputName]: {
        value: inputValue,
        ...validationFun(inputValue),
      },
    });
  }

  handleConfirmPasswordInputChange(event, validationFun) {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;
    this.setState({
      [inputName]: {
        value: inputValue,
        ...validationFun(this.state.newPassword.value, inputValue),
      },
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const passwordRequest = {
          confirmPassword: this.state.confirmPassword.value,
          newPassword: this.state.newPassword.value,
          oldPassword: this.state.currentPassword.value,
        };
        editPassword(passwordRequest)
          .then(response => {
            if (response.success) {
              this.props.handleLogout(
                "/login",
                "success",
                "You have successfully changed your password."
              );
            } else {
              notification.error({
                message: APP_NAME,
                description:
                  response.message ||
                  "Sorry! Something went wrong. Please try again!",
              });
            }
          })
          .catch(error => {
            notification.error({
              message: APP_NAME,
              description:
                error.message ||
                "Sorry! Something went wrong. Please try again!",
            });
          });
      }
    });
  }

  isFormInvalid() {
    return !(
      this.state.currentPassword.validateStatus === "success" &&
      this.state.confirmPassword.validateStatus === "success"
    );
  }

  render() {
    return (
      <Form onSubmit={this.handleSubmit} className="edit-password-form">
        <FormItem
          required
          label="Current Password"
          validateStatus={this.state.currentPassword.validateStatus}
          help={this.state.currentPassword.errorMsg}
        >
          <Input
            required
            prefix={<Icon type="unlock" />}
            size="large"
            type="password"
            name="currentPassword"
            placeholder="Current Password"
            onChange={event =>
              this.handleInputChange(event, validateLoginPassword)}
          />
        </FormItem>
        <FormItem
          style={{ marginTop: "32px" }}
          required
          label="New Password"
          validateStatus={this.state.newPassword.validateStatus}
          help={this.state.newPassword.errorMsg}
        >
          <Input
            required
            prefix={<Icon type="lock" />}
            size="large"
            type="password"
            name="newPassword"
            placeholder="New Password"
            onChange={event => this.handleInputChange(event, validatePassword)}
          />
        </FormItem>
        <FormItem
          required
          label="Confirm Password"
          validateStatus={this.state.confirmPassword.validateStatus}
          help={this.state.confirmPassword.errorMsg}
        >
          <Input
            required
            prefix={<Icon type="lock" />}
            size="large"
            type="password"
            name="confirmPassword"
            placeholder="Confirm Password"
            onChange={event =>
              this.handleConfirmPasswordInputChange(
                event,
                validateConfirmPassword
              )}
          />
        </FormItem>
        <FormItem>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            className="edit-password-button"
            disabled={this.isFormInvalid()}
          >
            Change Password
          </Button>
        </FormItem>
      </Form>
    );
  }
}

export default EditPassword;
