import { Form, Input, Button, notification } from "antd";
import React, { Component } from "react";
import { APP_NAME } from "../constants";
import { createThread } from "../util/APIUtils";

import { validateDescription, validateTitle } from "../util/Validators";

const FormItem = Form.Item;

class NewThread extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: {
        value: "",
      },
      description: {
        value: "",
      },
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.isFormInvalid = this.isFormInvalid.bind(this);
  }

  handleInputChange(event, validationFun) {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;

    this.setState({
      [inputName]: {
        value: inputValue,
        ...validationFun(inputValue),
      },
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    const threadRequest = {
      title: this.state.title.value,
      description: this.state.description.value,
    };
    createThread(threadRequest)
      .then(response => {
        if(response.success){
          notification.success({
            message: APP_NAME,
            description: "Thank you! You have successfully submitted a question.",
          });
          this.props.history.push("/forum");
        }
        else{
          notification.error({
            message: APP_NAME,
            description: response.message || "Sorry! Something went wrong. Please try again!",
          });
        }
        
      })
      .catch(error => {
        notification.error({
          message: APP_NAME,
          description:
            error.message || "Sorry! Something went wrong. Please try again!",
        });
      });
  }

  isFormInvalid() {
    return !(
      this.state.title.validateStatus === "success" &&
      this.state.description.validateStatus === "success"
    );
  }

  render() {
    return (
      <div className="signup-container">
        <h1 className="page-title">Ask a Question</h1>
        <div className="signup-content">
          <Form onSubmit={this.handleSubmit} className="signup-form">
            <FormItem
              label="Title"
              validateStatus={this.state.title.validateStatus}
              help={this.state.title.errorMsg}
              required
            >
              <Input
                required
                size="large"
                name="title"
                autoComplete="off"
                placeholder="Title"
                value={this.state.title.value}
                onChange={event => this.handleInputChange(event, validateTitle)}
              />
            </FormItem>
            <FormItem
              required
              label="Description"
              validateStatus={this.state.description.validateStatus}
              help={this.state.description.errorMsg}
            >
              <Input
                required
                size="large"
                name="description"
                autoComplete="off"
                placeholder="Description"
                value={this.state.description.value}
                onChange={event =>
                  this.handleInputChange(event, validateDescription)}
              />
            </FormItem>
            <FormItem>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                className="signup-form-button"
                disabled={this.isFormInvalid()}
              >
                Submit Question
              </Button>
            </FormItem>
          </Form>
        </div>
      </div>
    );
  }
}

export default NewThread;
