import { Form, Input, Button, notification } from "antd";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import LoadingIndicator from "../../common/LoadingIndicator";
import {
  API_BASE_URL,
  APP_NAME,
  NAME_MAX_LENGTH,
  NAME_MIN_LENGTH,
} from "../../constants";
import { signup } from "../../util/APIUtils";

import {
  validateCaptcha,
  validateEmail,
  validatePassword,
  validateUsername,
} from "../../util/Validators";
import "./Signup.css";
const FormItem = Form.Item;

class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: {
        value: "",
      },
      email: {
        value: "",
      },
      password: {
        value: "",
      },
      confirmPassword: {
        value: "",
      },
      captcha: {
        value: "",
      },
      captchaImgSrc: API_BASE_URL + "/auth/getCaptcha#" + Date.now(),
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validateUsernameAvailability = this.validateUsernameAvailability.bind(
      this
    );
    this.validateEmailAvailability = this.validateEmailAvailability.bind(this);
    this.isFormInvalid = this.isFormInvalid.bind(this);
  }

  handleInputChange(event, validationFun) {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;

    this.setState({
      [inputName]: {
        value: inputValue,
        ...validationFun(inputValue),
      },
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    const signupRequest = {
      email: this.state.email.value,
      username: this.state.username.value,
      password: this.state.password.value,
      captcha: this.state.captcha.value,
    };
    signup(signupRequest)
      .then(response => {
        notification.success({
          message: APP_NAME,
          description:
            "Thank you! You're successfully registered. Please Login to continue!",
        });
        this.props.history.push("/login");
      })
      .catch(error => {
        notification.error({
          message: APP_NAME,
          description:
            error.message || "Sorry! Something went wrong. Please try again!",
        });
        this.setState({
          captchaImgSrc: API_BASE_URL + "/auth/getCaptcha#" + Date.now(),
        });
      });
  }

  checkLoggedIn() {
    const { currentUser } = this.props;
    if (currentUser && currentUser.username) {
      this.props.history.push("/");
    }
  }

  isFormInvalid() {
    return !(
      this.state.username.validateStatus === "success" &&
      this.state.email.validateStatus === "success" &&
      this.state.password.validateStatus === "success" &&
      this.state.confirmPassword.validateStatus === "success"
    );
  }

  componentDidMount() {
    this.checkLoggedIn();
  }

  render() {
    return this.state.isLoading ? (
      <LoadingIndicator />
    ) : (
      <div className="signup-container">
        <h1 className="page-title">Sign Up</h1>
        <div className="signup-content">
          <Form onSubmit={this.handleSubmit} className="signup-form">
            <FormItem
              label="Username"
              hasFeedback
              validateStatus={this.state.username.validateStatus}
              help={this.state.username.errorMsg}
            >
              <Input
                size="large"
                name="username"
                autoComplete="off"
                placeholder="A unique username"
                value={this.state.username.value}
                onBlur={this.validateUsernameAvailability}
                onChange={event =>
                  this.handleInputChange(event, validateUsername)}
              />
            </FormItem>
            <FormItem
              label="Email"
              hasFeedback
              validateStatus={this.state.email.validateStatus}
              help={this.state.email.errorMsg}
            >
              <Input
                size="large"
                name="email"
                type="email"
                autoComplete="off"
                placeholder="Your email"
                value={this.state.email.value}
                onBlur={this.validateEmailAvailability}
                onChange={event => this.handleInputChange(event, validateEmail)}
              />
            </FormItem>
            <FormItem
              label="Password"
              validateStatus={this.state.password.validateStatus}
              help={this.state.password.errorMsg}
            >
              <Input
                size="large"
                name="password"
                type="password"
                autoComplete="off"
                placeholder="A password between 8 to 15 characters"
                value={this.state.password.value}
                onChange={event =>
                  this.handleInputChange(event, validatePassword)}
              />
            </FormItem>
            <FormItem
              label="Confirm Password"
              validateStatus={this.state.confirmPassword.validateStatus}
              help={this.state.confirmPassword.errorMsg}
            >
              <Input
                size="large"
                name="confirmPassword"
                type="password"
                autoComplete="off"
                placeholder="Confirm Password"
                value={this.state.confirmPassword.value}
                onChange={event =>
                  this.handleInputChange(event, this.validateConfirmPassword)}
              />
            </FormItem>
            <FormItem
              label="Captcha"
              validateStatus={this.state.captcha.validateStatus}
              help={this.state.captcha.errorMsg}
            >
              <img alt="something_awesome" src={this.state.captchaImgSrc} />
              <Input
                size="large"
                name="captcha"
                type="captcha"
                autoComplete="off"
                placeholder="Type the captcha here."
                value={this.state.captcha.value}
                onChange={event =>
                  this.handleInputChange(event, validateCaptcha)}
              />
            </FormItem>
            <FormItem>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                className="signup-form-button"
                disabled={this.isFormInvalid()}
              >
                Sign up
              </Button>
              Already registered? <Link to="/login">Login now!</Link>
            </FormItem>
          </Form>
        </div>
      </div>
    );
  }

  // Validation Functions

  validateName = name => {
    if (name.length < NAME_MIN_LENGTH) {
      return {
        validateStatus: "error",
        errorMsg: `Name is too short (Minimum ${NAME_MIN_LENGTH} characters needed.)`,
      };
    } else if (name.length > NAME_MAX_LENGTH) {
      return {
        validateStatus: "error",
        errorMsg: `Name is too long (Maximum ${NAME_MAX_LENGTH} characters allowed.)`,
      };
    } else {
      return {
        validateStatus: "success",
        errorMsg: null,
      };
    }
  };

  validateUsernameAvailability() {
    // First check for client side errors in username
    const usernameValue = this.state.username.value;
    const usernameValidation = validateUsername(usernameValue);

    if (usernameValidation.validateStatus === "error") {
      this.setState({
        username: {
          value: usernameValue,
          ...usernameValidation,
        },
      });
      return;
    }

    this.setState({
      username: {
        value: usernameValue,
        validateStatus: "success",
        errorMsg: null,
      },
    });
  }

  validateEmailAvailability() {
    // First check for client side errors in email
    const emailValue = this.state.email.value;
    const emailValidation = validateEmail(emailValue);

    if (emailValidation.validateStatus === "error") {
      this.setState({
        email: {
          value: emailValue,
          ...emailValidation,
        },
      });
      return;
    }

    this.setState({
      email: {
        value: emailValue,
        validateStatus: "success",
        errorMsg: null,
      },
    });
  }

  validateConfirmPassword = confirmPassword => {
    if (confirmPassword !== this.state.password.value) {
      return {
        validateStatus: "error",
        errorMsg: `Confirm password does not match password.`,
      };
    } else {
      return {
        validateStatus: "success",
        errorMsg: null,
      };
    }
  };
}

export default Signup;
