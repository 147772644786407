import { Form, Input, Button, notification, Select, InputNumber } from "antd";
import React, { Component } from "react";
import LoadingIndicator from "../common/LoadingIndicator";

import {
  APP_NAME,
  TRANSACTION_AMOUNT_MIN,
  TRANSACTION_AMOUNT_MAX,
} from "../constants";
import {
  editIncomeTransaction,
  getIncomeById,
  getIncomeCategory,
} from "../util/APIUtils";
import {
  validateTransactionAmount,
  validateTransactionDescription,
} from "../util/Validators";

const { Option } = Select;

const FormItem = Form.Item;

class EditIncome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category: {
        value: "",
        placeholder: "Please select a type above",
      },
      amount: {
        value: 1,
        validateStatus: "success",
      },
      description: {
        value: "",
        validateStatus: "success",
      },
      categoryList: [],
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.loadIncomeCategory = this.loadIncomeCategory.bind(this);
    this.isFormInvalid = this.isFormInvalid.bind(this);
  }

  loadIncomeCategory() {
    const { currentUser } = this.props;
    let promise;
    if (currentUser && currentUser.username) {
      promise = getIncomeCategory();
    }
    if (!promise) {
      return;
    }

    this.setState({
      isLoading: true,
    });
    promise
      .then(response => {
        this.setState({
          categoryList: response,
          isLoading: false,
        });
      })
      .catch(error => {
        if (error.status === 401) {
          this.props.handleSessionTimeout();
        }
        this.setState({
          isLoading: false,
        });
      });
  }

  loadIncomeTransaction(incomeId) {
    const { currentUser } = this.props;
    let promise;

    if (currentUser && currentUser.username) {
      promise = getIncomeById(incomeId);
    }
    if (!promise) {
      return;
    }

    this.setState({
      isLoading: true,
    });

    promise
      .then(response => {
        if (!response.description) {
          notification.error({
            message: APP_NAME,
            description: "Sorry! Something went wrong. Please try again!",
          });
          this.props.history.push("/");
        } else {
          this.setState({
            category: {
              value: response.category.name,
              validateStatus: "success",
            },
            amount: {
              value: response.amount,
              validateStatus: "success",
            },
            description: {
              value: response.description,
              validateStatus: "success",
            },
            isLoading: false,
          });
        }
      })
      .catch(error => {
        notification.error({
          message: APP_NAME,
          description: "Sorry! Something went wrong. Please try again!",
        });
        this.props.history.push("/");
        this.setState({
          isLoading: false,
        });
      });
  }

  handleInputChange(event, validationFun) {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;

    this.setState({
      [inputName]: {
        value: inputValue,
        ...validationFun(inputValue),
      },
    });
  }

  handleNumberChange = (value, validationFun) => {
    this.setState({
      amount: {
        ...validationFun(value),
        value,
      },
    });
  };

  handleSelectChange(value) {
    this.setState({
      category: {
        value,
        validateStatus: "success",
      },
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    const transactionRequest = {
      amount: this.state.amount.value,
      description: this.state.description.value,
    };
    editIncomeTransaction(
      transactionRequest,
      this.state.category.value,
      this.state.incomeId
    )
      .then(response => {
        if (response.success) {
          notification.success({
            message: APP_NAME,
            description: "You have successfully edited an income transaction!",
          });
          this.props.history.push(
            "/wallet/" + this.state.walletId + "/transaction"
          );
        } else {
          notification.error({
            message: APP_NAME,
            description:
              response.message ||
              "Sorry! Something went wrong. Please try again!",
          });
        }
      })
      .catch(error => {
        notification.error({
          message: APP_NAME,
          description:
            error.message || "Sorry! Something went wrong. Please try again!",
        });
      });
  }

  isFormInvalid() {
    return !(
      this.state.category.validateStatus === "success" &&
      this.state.description.validateStatus === "success" &&
      this.state.amount.validateStatus === "success"
    );
  }

  componentDidMount() {
    const { match } = this.props;
    const incomeId = match.params.incomeId;
    const walletId = match.params.walletId;
    this.setState({
      incomeId,
      walletId,
    });
    this.loadIncomeCategory();
    this.loadIncomeTransaction(incomeId);
  }

  render() {
    const { category, categoryList } = this.state;
    return this.state.isLoading ? (
      <LoadingIndicator />
    ) : (
      <div className="signup-container">
        <h1 className="page-title">Edit Transaction</h1>
        <div className="signup-content">
          <Form onSubmit={this.handleSubmit} className="signup-form">
            <FormItem label="Category" hasFeedback required>
              <Select
                disabled={category.state}
                value={category.value}
                placeholder={category.placeholder}
                onChange={event => this.handleSelectChange(event)}
              >
                {categoryList.map((category, categoryIndex) => (
                  <Option key={category.name} value={category.name}>
                    {category.name}
                  </Option>
                ))}
              </Select>
            </FormItem>
            <FormItem
              required
              label="Amount"
              validateStatus={this.state.amount.validateStatus}
              help={this.state.amount.errorMsg}
            >
              <InputNumber
                required
                size="large"
                name="amount"
                min={TRANSACTION_AMOUNT_MIN}
                max={TRANSACTION_AMOUNT_MAX}
                step={0.01}
                prefix="$"
                suffix="SGD"
                value={this.state.amount.value}
                onChange={event =>
                  this.handleNumberChange(event, validateTransactionAmount)}
              />
            </FormItem>
            <FormItem
              required
              label="Description"
              validateStatus={this.state.description.validateStatus}
              help={this.state.description.errorMsg}
            >
              <Input
                size="large"
                name="description"
                autoComplete="off"
                placeholder="Description"
                value={this.state.description.value}
                onChange={event =>
                  this.handleInputChange(event, validateTransactionDescription)}
              />
            </FormItem>
            <FormItem>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                className="signup-form-button"
                disabled={this.isFormInvalid()}
              >
                Update
              </Button>
            </FormItem>
          </Form>
        </div>
      </div>
    );
  }
}

export default EditIncome;
