import { Typography } from "antd";
import React, { Component } from "react";
import LoadingIndicator from "./LoadingIndicator";

const { Title } = Typography;

class Message extends Component {
  componentDidMount() {
    const { history: { push } } = this.props;
    if (this.props.loadFunction) {
      this.props.loadFunction();
    }
    setTimeout(() => push("/login"), 2000);
  }

  render() {
    return (
      <div className="page-not-found">
        <Title level={2} className="margin0">
          {this.props.messageTitle}
        </Title>
        <div className="desc">{this.props.messageDescription}</div>
        <LoadingIndicator />
      </div>
    );
  }
}

export default Message;
