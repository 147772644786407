import { Form, Input, Button, notification, InputNumber } from "antd";
import React, { Component } from "react";
import { APP_NAME, AMOUNT_MIN_VAL, AMOUNT_MAX_VAL } from "../constants";
import { createWallet } from "../util/APIUtils";
import { validateWalletName, validateBalance } from "../util/Validators";

const FormItem = Form.Item;

class NewWallet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category: {
        value: "",
      },
      name: {
        value: "",
      },
      balance: {
        value: 5000,
        validateStatus: "success",
      },
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.isFormInvalid = this.isFormInvalid.bind(this);
  }

  handleInputChange(event, validationFun) {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;

    this.setState({
      [inputName]: {
        value: inputValue,
        ...validationFun(inputValue),
      },
    });
  }

  handleNumberChange = (value, validationFun) => {
    this.setState({
      balance: {
        ...validationFun(value),
        value,
      },
    });
  };

  handleSelectChange(value, validationFun) {
    this.setState({
      category: {
        value,
        ...validationFun(value),
      },
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    const walletRequest = {
      category: this.state.category.value,
      balance: this.state.balance.value,
      name: this.state.name.value,
    };
    createWallet(walletRequest)
      .then(response => {
        if(response.success){
          notification.success({
            message: APP_NAME,
            description: "Thank you! You have successfully created a new wallet.",
          });
          this.props.history.push("/");
        }
        else{
          notification.error({
            message: APP_NAME,
            description: response.message || "Sorry! Something went wrong. Please try again!",
          });
        }
      })
      .catch(error => {
        notification.error({
          message: APP_NAME,
          description:
            error.message || "Sorry! Something went wrong. Please try again!",
        });
      });
  }

  isFormInvalid() {
    return !(
      this.state.name.validateStatus === "success" &&
      this.state.balance.validateStatus === "success"
    );
  }

  render() {
    return (
      <div className="signup-container">
        <h1 className="page-title">Create New Wallet</h1>
        <div className="signup-content">
          <Form onSubmit={this.handleSubmit} className="signup-form">
            <FormItem
              required
              label="Name"
              validateStatus={this.state.name.validateStatus}
              help={this.state.name.errorMsg}
            >
              <Input
                required
                size="large"
                name="name"
                autoComplete="off"
                placeholder="Name"
                value={this.state.name.value}
                onChange={event =>
                  this.handleInputChange(event, validateWalletName)}
              />
            </FormItem>
            <FormItem
              required
              label="Initial Balance"
              validateStatus={this.state.balance.validateStatus}
              help={this.state.balance.errorMsg}
            >
              <InputNumber
                required
                size="large"
                name="balance"
                min={AMOUNT_MIN_VAL}
                max={AMOUNT_MAX_VAL}
                step={0.01}
                defaultValue={5000}
                prefix="$"
                suffix="SGD"
                value={this.state.balance.value}
                onChange={event =>
                  this.handleNumberChange(event, validateBalance)}
              />
            </FormItem>
            <FormItem>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                className="signup-form-button"
                disabled={this.isFormInvalid()}
              >
                Create
              </Button>
            </FormItem>
          </Form>
        </div>
      </div>
    );
  }
}

export default NewWallet;
