import { Form, Input, Button, Icon, notification } from "antd";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { APP_NAME } from "../../constants";
import { resetPassword } from "../../util/APIUtils";

import { validateEmail } from "../../util/Validators";
import "./ForgotPassword.css";
const FormItem = Form.Item;

class ForgotPassword extends Component {
  render() {
    const AntWrappedForgotPasswordForm = Form.create()(ForgotPasswordForm);
    return (
      <div className="forgotpassword-container">
        <h1 className="page-title">Forgot Password</h1>
        <div className="forgotpassword-content">
          <AntWrappedForgotPasswordForm
            history={this.props.history}
            currentUser={this.props.currentUser}
            onLogin={this.props.onLogin}
          />
        </div>
      </div>
    );
  }
}

class ForgotPasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: {
        value: "",
      },
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.isFormInvalid = this.isFormInvalid.bind(this);
  }

  handleInputChange(event, validationFun) {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;
    this.setState({
      [inputName]: {
        value: inputValue,
        ...validationFun(inputValue),
      },
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const forgotPasswordRequest = {
          email: this.state.email.value,
        };
        resetPassword(forgotPasswordRequest)
          .then(response => {
            notification.success({
              message: APP_NAME,
              description: "A confirmation email sent to the email address.",
            });
            this.props.history.push("/login");
          })
          .catch(error => {
            notification.error({
              message: APP_NAME,
              description:
                error.message ||
                "Sorry! Something went wrong. Please try again!",
            });
          });
      }
    });
  }

  checkLoggedIn() {
    const { currentUser } = this.props;
    if (currentUser && currentUser.username) {
      this.props.history.push("/");
    }
  }

  componentDidMount() {
    this.checkLoggedIn();
  }

  isFormInvalid() {
    return this.state.email.validateStatus !== "success";
  }

  render() {
    return (
      <Form onSubmit={this.handleSubmit} className="login-form">
        <FormItem
          required
          label="Email"
          validateStatus={this.state.email.validateStatus}
          help={this.state.email.errorMsg}
        >
          <Input
            prefix={<Icon type="mail" />}
            size="large"
            name="email"
            placeholder="Email"
            onChange={event => this.handleInputChange(event, validateEmail)}
          />
        </FormItem>
        <FormItem>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            className="forgotpassword-form-button"
            disabled={this.isFormInvalid()}
          >
            Send password reset email
          </Button>
          <p style={{ textAlign: "center" }}>
            Or <Link to="/login">Login now!</Link>
          </p>
        </FormItem>
      </Form>
    );
  }
}

export default ForgotPassword;
