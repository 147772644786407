import {
  Form,
  Input,
  Button,
  notification,
  Select,
  Radio,
  InputNumber,
} from "antd";
import React, { Component } from "react";
import {
  APP_NAME,
  TRANSACTION_AMOUNT_MAX,
  TRANSACTION_AMOUNT_MIN,
} from "../constants";

import {
  createExpenseTransaction,
  createIncomeTransaction,
  getExpensesCategory,
  getIncomeCategory,
} from "../util/APIUtils";
import {
  validateTransactionAmount,
  validateTransactionDescription,
} from "../util/Validators";

const { Option } = Select;

const FormItem = Form.Item;

class AddTransaction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: {
        value: "",
      },
      category: {
        value: "",
        state: "disabled",
        placeholder: "Please select a type above",
      },
      amount: {
        value: 10,
        validateStatus: "success",
      },
      description: {
        value: "",
      },
      categoryList: [],
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.loadExpenseCategory = this.loadExpenseCategory.bind(this);
    this.loadIncomeCategory = this.loadIncomeCategory.bind(this);
    this.isFormInvalid = this.isFormInvalid.bind(this);
  }

  loadExpenseCategory() {
    const { currentUser } = this.props;
    let promise;
    if (currentUser && currentUser.username) {
      promise = getExpensesCategory();
    }
    if (!promise) {
      return;
    }

    this.setState({
      isLoading: true,
    });
    promise
      .then(response => {
        this.setState({
          categoryList: response,
          isLoading: false,
        });
      })
      .catch(error => {
        if (error.status === 401) {
          this.props.handleSessionTimeout();
        }
        this.setState({
          isLoading: false,
        });
      });
  }

  loadIncomeCategory() {
    const { currentUser } = this.props;
    let promise;
    if (currentUser && currentUser.username) {
      promise = getIncomeCategory();
    }
    if (!promise) {
      return;
    }

    this.setState({
      isLoading: true,
    });
    promise
      .then(response => {
        this.setState({
          categoryList: response,
          isLoading: false,
        });
      })
      .catch(error => {
        if (error.status === 401) {
          this.props.handleSessionTimeout();
        }
        this.setState({
          isLoading: false,
        });
      });
  }

  handleInputChange(event, validationFun) {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;

    this.setState({
      [inputName]: {
        value: inputValue,
        ...validationFun(inputValue),
      },
    });
  }

  handleNumberChange = (value, validationFun) => {
    this.setState({
      amount: {
        ...validationFun(value),
        value,
      },
    });
  };

  handleSelectChange(value) {
    this.setState({
      category: {
        value,
        validateStatus: "success",
      },
    });
  }

  handleRadioChange(value) {
    if (value.target.value === "income") {
      this.loadIncomeCategory();
    } else {
      this.loadExpenseCategory();
    }
    this.setState({
      type: {
        value: value.target.value,
        validateStatus: "success",
      },
      category: {
        value: "",
        validateStatus: "",
        state: false,
        placeholder: "Please select a Category",
      },
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    const transactionRequest = {
      amount: this.state.amount.value,
      description: this.state.description.value,
    };
    if (this.state.type.value === "expense") {
      createExpenseTransaction(
        transactionRequest,
        this.state.category.value,
        this.state.walletId
      )
        .then(response => {
          if (response.success) {
            notification.success({
              message: APP_NAME,
              description:
                "You have successfully added an expense transaction!",
            });
            this.props.history.push(
              "/wallet/" + this.state.walletId + "/transaction"
            );
          } else {
            notification.error({
              message: APP_NAME,
              description:
                response.message ||
                "Sorry! Something went wrong. Please try again!",
            });
          }
        })
        .catch(error => {
          notification.error({
            message: APP_NAME,
            description: "Sorry! Something went wrong. Please try again!",
          });
        });
    } else {
      createIncomeTransaction(
        transactionRequest,
        this.state.category.value,
        this.state.walletId
      )
        .then(response => {
          if (response.success) {
            notification.success({
              message: APP_NAME,
              description: "You have successfully added an income transaction!",
            });
            this.props.history.push(
              "/wallet/" + this.state.walletId + "/transaction"
            );
          } else {
            notification.error({
              message: APP_NAME,
              description:
                response.message ||
                "Sorry! Something went wrong. Please try again!",
            });
          }
        })
        .catch(error => {
          notification.error({
            message: APP_NAME,
            description: "Sorry! Something went wrong. Please try again!",
          });
        });
    }
  }

  isFormInvalid() {
    return !(
      this.state.category.validateStatus === "success" &&
      this.state.description.validateStatus === "success" &&
      this.state.amount.validateStatus === "success"
    );
  }

  componentDidMount() {
    const { match } = this.props;
    const walletId = match.params.walletId;
    this.setState({
      walletId,
    });
  }

  render() {
    const { category, categoryList } = this.state;
    return (
      <div className="signup-container">
        <h1 className="page-title">Add New Transaction</h1>
        <div className="signup-content">
          <Form onSubmit={this.handleSubmit} className="signup-form">
            <FormItem label="Type" required>
              <Radio.Group onChange={event => this.handleRadioChange(event)}>
                <Radio.Button value="expense">Expense</Radio.Button>
                <Radio.Button value="income">Income</Radio.Button>
              </Radio.Group>
            </FormItem>
            <FormItem label="Category" hasFeedback required>
              <Select
                disabled={category.state}
                placeholder={category.placeholder}
                value={this.state.category.value}
                onChange={event => this.handleSelectChange(event)}
              >
                {categoryList.map((category, categoryIndex) => (
                  <Option key={categoryIndex} value={category.name}>
                    {category.name}
                  </Option>
                ))}
              </Select>
            </FormItem>
            <FormItem
              required
              label="Amount"
              validateStatus={this.state.amount.validateStatus}
              help={this.state.amount.errorMsg}
            >
              <InputNumber
                required
                size="large"
                name="amount"
                min={TRANSACTION_AMOUNT_MIN}
                max={TRANSACTION_AMOUNT_MAX}
                step={0.01}
                defaultValue={1}
                prefix="$"
                suffix="SGD"
                value={this.state.amount.value}
                onChange={event =>
                  this.handleNumberChange(event, validateTransactionAmount)}
              />
            </FormItem>
            <FormItem
              required
              label="Description"
              validateStatus={this.state.description.validateStatus}
              help={this.state.description.errorMsg}
            >
              <Input
                size="large"
                name="description"
                autoComplete="off"
                placeholder="Description"
                value={this.state.description.value}
                onChange={event =>
                  this.handleInputChange(event, validateTransactionDescription)}
              />
            </FormItem>
            <FormItem>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                className="signup-form-button"
                disabled={this.isFormInvalid()}
              >
                Create
              </Button>
            </FormItem>
          </Form>
        </div>
      </div>
    );
  }
}

export default AddTransaction;
