import { Card, Button, Breadcrumb, Icon, notification, Popconfirm } from "antd";
import moment from "moment";
import React, { Component } from "react";
import Moment from "react-moment";

import { Link } from "react-router-dom";
import LoadingIndicator from "../common/LoadingIndicator";
import { APP_NAME } from "../constants";
import {
  deleteComment,
  deleteThread,
  getCommentByThreadId,
  getThreadById,
  signup,
} from "../util/APIUtils";

class Thread extends Component {
  constructor(props) {
    super(props);
    this.state = {
      thread: {
        value: "",
      },
      comments: [],
      description: {
        value: "",
      },
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.isFormInvalid = this.isFormInvalid.bind(this);
  }

  handleInputChange(event, validationFun) {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;

    this.setState({
      [inputName]: {
        value: inputValue,
        ...validationFun(inputValue),
      },
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    const signupRequest = {
      description: this.state.description.value,
    };
    signup(signupRequest)
      .then(response => {
        notification.success({
          message: APP_NAME,
          description: "Thank you! You have successfully commented.",
        });
        this.props.history.push("/login");
      })
      .catch(error => {
        notification.error({
          message: APP_NAME,
          description:
            error.message || "Sorry! Something went wrong. Please try again!",
        });
      });
  }

  isFormInvalid() {
    return this.state.description.validateStatus !== "success";
  }

  loadThread(threadId) {
    const { currentUser } = this.props;
    let promise;
    if (currentUser && currentUser.username) {
      promise = getThreadById(threadId);
    }
    if (!promise) {
      return;
    }

    this.setState({
      isLoading: true,
    });
    promise
      .then(response => {
        if (response !== null) {
          this.setState({
            thread: response,
          });
        } else {
          notification.error({
            message: APP_NAME,
            description: "Sorry! Something went wrong. Please try again!",
          });
          this.props.history.push("/forum");
        }
      })
      .catch(error => {
        if (error.status === 401) {
          this.props.handleSessionTimeout();
        } else {
          notification.error({
            message: APP_NAME,
            description: "Sorry! Something went wrong. Please try again!",
          });
          this.props.history.push("/forum");
        }
      });
  }

  loadComments(threadId) {
    const { currentUser } = this.props;
    let promise;
    if (currentUser && currentUser.username) {
      promise = getCommentByThreadId(threadId);
    }
    if (!promise) {
      return;
    }

    this.setState({
      isLoading: true,
    });
    promise
      .then(response => {
        this.setState({
          comments: response,
          isLoading: false,
        });
      })
      .catch(error => {
        this.setState({
          isLoading: false,
        });
      });
  }

  deleteThreadHandler(threadId) {
    const { currentUser } = this.props;
    let promise;
    if (currentUser && currentUser.username) {
      deleteThread(threadId)
        .then(response => {
          notification.success({
            message: APP_NAME,
            description: "You have successfully deleted a thread.",
          });
          this.props.history.push("/forum");
        })
        .catch(error => {
          notification.error({
            message: APP_NAME,
            description:
              error.message || "Sorry! Something went wrong. Please try again!",
          });
        });
    }
    if (!promise) {
      return;
    }
  }

  deleteCommentHandler(commentId) {
    const { threadId } = this.state;
    const { currentUser } = this.props;
    let promise;
    if (currentUser && currentUser.username) {
      deleteComment(commentId)
        .then(response => {
          notification.success({
            message: APP_NAME,
            description: "You have successfully deleted a comment.",
          });
          this.loadComments(threadId);
        })
        .catch(error => {
          notification.error({
            message: APP_NAME,
            description:
              error.message || "Sorry! Something went wrong. Please try again!",
          });
        });
    }
    if (!promise) {
      return;
    }
  }

  componentDidMount() {
    const { match } = this.props;
    const threadId = match.params.threadId;
    this.setState({
      threadId,
    });
    this.loadThread(threadId);
    this.loadComments(threadId);
  }

  render() {
    const { comments, thread, threadId } = this.state;
    const { currentUser } = this.props;
    return this.state.isLoading ? (
      <LoadingIndicator />
    ) : (
      <div>
        <Breadcrumb style={{ margin: "32px 0" }}>
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/forum">Forum</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item style={{ wordWrap: "break-word" }}>
            {thread.title}
          </Breadcrumb.Item>
        </Breadcrumb>
        <Card
          title={thread.title}
          style={{ margin: "32px 0" }}
          bodyStyle={{ wordWrap: "break-word" }}
          extra={
            <Link to={`/forum/thread/${threadId}/comment/new`}>
              <Button>
                <Icon type="plus" /> Comment
              </Button>
            </Link>
          }
        >
          <p
            style={{
              fontSize: 14,
              color: "rgba(0, 0, 0, 0.85)",
              marginBottom: 16,
              fontWeight: 500,
            }}
          />
          <Card
            type="inner"
            title={
              <div>
                {thread.userAccount && thread.userAccount.username} asked{" "}
                <Moment fromNow>{thread.createdOn}</Moment>
              </div>
            }
            extra={
              thread.userAccount &&
              thread.userAccount.username === currentUser.username && (
                <React.Fragment>
                  <Link to={`/forum/thread/${threadId}/edit`}>
                    <Button type="link">Edit</Button>
                  </Link>
                  <Popconfirm
                    title="Are you sure？"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={() => this.deleteThreadHandler(threadId)}
                  >
                    <Button type="link">Delete</Button>
                  </Popconfirm>
                </React.Fragment>
              )
            }
          >
            {thread.description}
          </Card>
          {comments.map((comment, commentIndex) => (
            <Card
              key={commentIndex}
              style={{ marginTop: 16, wordWrap: "break-word" }}
              bodyStyle={{ wordWrap: "break-word" }}
              type="inner"
              title={`${comment.userAccount &&
                comment.userAccount.username} commented ${moment(
                comment.createdOn
              ).fromNow()}`}
              extra={
                comment.userAccount &&
                comment.userAccount.username === currentUser.username && (
                  <React.Fragment>
                    <Link
                      to={`/forum/thread/${threadId}/comment/${comment.commentId}/edit`}
                    >
                      Edit
                    </Link>
                    <Popconfirm
                      title="Are you sure？"
                      okText="Yes"
                      cancelText="No"
                      onConfirm={() =>
                        this.deleteCommentHandler(comment.commentId)}
                    >
                      <Button type="link">Delete</Button>
                    </Popconfirm>
                  </React.Fragment>
                )
              }
            >
              {comment.content}
            </Card>
          ))}
        </Card>
      </div>
    );
  }
}

export default Thread;
