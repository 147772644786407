export const APP_NAME = "MooLahLah";
export const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "/api";
export const ACCESS_TOKEN = "accessToken";

export const NAME_MIN_LENGTH = 4;
export const NAME_MAX_LENGTH = 40;

export const WALLET_NAME_MIN_LENGTH = 6;
export const WALLET_NAME_MAX_LENGTH = 15;
export const WALLET_NAME_REGEX = RegExp("^[a-zA-Z0-9\\s\\-]{6,15}$");

export const USERNAME_MIN_LENGTH = 6;
export const USERNAME_MAX_LENGTH = 15;
export const USERNAME_REGEX = RegExp("^[a-zA-Z0-9._-]{6,15}$");

export const EMAIL_MAX_LENGTH = 40;
export const EMAIL_REGEX = RegExp("[^@ ]+@[^@ ]+\\.[^@ ]+");

export const PASSWORD_MIN_LENGTH = 8;
export const PASSWORD_MAX_LENGTH = 64;
export const PASSWORD_REGEX = RegExp(
  "((?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()\\-+=\\[\\]{}|\\\\:;\"'<,>.?/~`_]).{8,64})"
);

export const AMOUNT_MIN_VAL = 0;
export const AMOUNT_MAX_VAL = 1000000;

export const DESCRIPTION_MIN_LENGTH = 8;
export const DESCRIPTION_MAX_LENGTH = 64;

export const FORUM_THREAD_TITLE_MIN_LENGTH = 5;
export const FORUM_THREAD_TITLE_MAX_LENGTH = 255;
export const FORUM_THREAD_DESCRIPTION_MIN_LENGTH = 100;
export const FORUM_THREAD_DESCRIPTION_MAX_LENGTH = 65535;

export const FORUM_COMMENT_DESCRIPTION_MIN_LENGTH = 10;
export const FORUM_COMMENT_DESCRIPTION_MAX_LENGTH = 65535;

export const TRANSACTION_DESCRIPTION_MIN_LENGTH = 8;
export const TRANSACTION_DESCRIPTION_MAX_LENGTH = 64;

export const TRANSACTION_AMOUNT_MIN = 0.01;
export const TRANSACTION_AMOUNT_MAX = 1000000;
