import { Form, Input, Button, Icon, notification } from "antd";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { ACCESS_TOKEN, APP_NAME } from "../../constants";
import { login } from "../../util/APIUtils";
import { validateEmail, validateLoginPassword } from "../../util/Validators";

import "./Login.css";
const FormItem = Form.Item;

class Login extends Component {
  render() {
    const AntWrappedLoginForm = Form.create()(LoginForm);
    return (
      <div className="login-container">
        <h1 className="page-title">Login</h1>
        <div className="login-content">
          <AntWrappedLoginForm
            history={this.props.history}
            currentUser={this.props.currentUser}
            onLogin={this.props.onLogin}
          />
        </div>
      </div>
    );
  }
}

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: {
        value: "",
      },
      password: {
        value: "",
      },
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.isFormInvalid = this.isFormInvalid.bind(this);
  }

  handleInputChange(event, validationFun) {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;

    this.setState({
      [inputName]: {
        value: inputValue,
        ...validationFun(inputValue),
      },
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const loginRequest = {
          email: this.state.email.value,
          password: this.state.password.value,
        };
        login(loginRequest)
          .then(response => {
            localStorage.setItem(ACCESS_TOKEN, response.accessToken);
            this.props.onLogin();
          })
          .catch(error => {
            if (error.status === 401) {
              notification.error({
                message: APP_NAME,
                description:
                  "Your Email or Password is incorrect. Please try again!",
              });
            } else {
              notification.error({
                message: APP_NAME,
                description:
                  error.message ||
                  "Sorry! Something went wrong. Please try again!",
              });
            }
          });
      }
    });
  }

  checkLoggedIn() {
    const { currentUser } = this.props;
    if (currentUser && currentUser.username) {
      this.props.history.push("/");
    }
  }

  isFormInvalid() {
    return !(
      this.state.email.validateStatus === "success" &&
      this.state.password.validateStatus === "success"
    );
  }

  componentDidMount() {
    this.checkLoggedIn();
  }

  render() {
    return (
      <Form onSubmit={this.handleSubmit} className="login-form">
        <FormItem
          validateStatus={this.state.email.validateStatus}
          help={this.state.email.errorMsg}
        >
          <Input
            prefix={<Icon type="user" />}
            size="large"
            name="email"
            placeholder="Email"
            onChange={event => this.handleInputChange(event, validateEmail)}
          />
        </FormItem>
        <FormItem
          validateStatus={this.state.password.validateStatus}
          help={this.state.password.errorMsg}
        >
          <Input
            prefix={<Icon type="lock" />}
            size="large"
            name="password"
            type="password"
            placeholder="Password"
            onChange={event =>
              this.handleInputChange(event, validateLoginPassword)}
          />
        </FormItem>
        <FormItem>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            className="login-form-button"
            disabled={this.isFormInvalid()}
          >
            Login
          </Button>
          <div style={{ textAlign: "center" }}>
            Or <Link to="/signup">Register now!</Link>
            <br />
            Forgot your password?{" "}
            <Link to="/forgot-password">Forgot Password</Link>
          </div>
        </FormItem>
      </Form>
    );
  }
}

export default Login;
