import { Layout, notification } from "antd";
import React, { Component } from "react";
import { Route, withRouter, Switch } from "react-router-dom";

import EditComment from "../comment/EditComment";
import NewComment from "../comment/NewComment";

import AppHeader from "../common/AppHeader";
import LoadingIndicator from "../common/LoadingIndicator";
import Message from "../common/Message";
import NotFound from "../common/NotFound";
import PrivateRoute from "../common/PrivateRoute";
import { ACCESS_TOKEN, APP_NAME } from "../constants";
import Forum from "../forum/Forum";
import EditThread from "../thread/EditThread";
import NewThread from "../thread/NewThread";

import Thread from "../thread/Thread";
import AddTransaction from "../transaction/AddTransaction";
import EditExpense from "../transaction/EditExpense";
import EditIncome from "../transaction/EditIncome";
import Transaction from "../transaction/Transaction";
import EditPassword from "../user/edit-password/EditPassword";
import ForgotPassword from "../user/forgot-password/ForgotPassword";
import Login from "../user/login/Login";
import NewPassword from "../user/new-password/NewPassword";
import ResetPassword from "../user/reset-password/ResetPassword";
import Signup from "../user/signup/Signup";
import VerifyEmail from "../user/VerifyEmail";
import { getCurrentUser } from "../util/APIUtils";
import EditWallet from "../wallet/EditWallet";
import NewWallet from "../wallet/NewWallet";
import Wallet from "../wallet/Wallet";
import "./App.css";
const { Content } = Layout;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: null,
      isAuthenticated: false,
      isLoading: false,
    };
    this.handleLogout = this.handleLogout.bind(this);
    this.loadCurrentUser = this.loadCurrentUser.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.handleSessionTimeout = this.handleSessionTimeout.bind(this);

    notification.config({
      placement: "topRight",
      top: 70,
      duration: 3,
    });
  }

  loadCurrentUser() {
    this.setState({
      isLoading: true,
    });
    getCurrentUser()
      .then(response => {
        this.setState({
          currentUser: response,
          isAuthenticated: true,
          isLoading: false,
        });
      })
      .catch(error => {
        this.setState({
          isLoading: false,
        });
      });
  }

  componentWillMount() {
    this.loadCurrentUser();
  }

  handleLogout(
    redirectTo = "/",
    notificationType = "success",
    description = "You're successfully logged out."
  ) {
    localStorage.removeItem(ACCESS_TOKEN);

    this.setState({
      currentUser: null,
      isAuthenticated: false,
    });

    this.props.history.push(redirectTo);

    notification[notificationType]({
      message: APP_NAME,
      description: description,
    });
  }

  handleSessionTimeout(
    redirectTo = "/",
    notificationType = "error",
    description = "Session Timeout. Please login and try again."
  ) {
    localStorage.removeItem(ACCESS_TOKEN);

    this.setState({
      currentUser: null,
      isAuthenticated: false,
    });

    this.props.history.push(redirectTo);

    notification[notificationType]({
      message: APP_NAME,
      description: description,
    });
  }

  handleLogin() {
    notification.success({
      message: APP_NAME,
      description: "You're successfully logged in.",
    });
    this.loadCurrentUser();
    this.props.history.push("/");
  }

  render() {
    if (this.state.isLoading) {
      return <LoadingIndicator />;
    }
    const { isAuthenticated, currentUser } = this.state;
    return (
      <Layout className="app-container">
        <AppHeader
          isAuthenticated={isAuthenticated}
          currentUser={currentUser}
          onLogout={this.handleLogout}
        />

        <Content className="app-content">
          <div className="container">
            <Switch>
              <PrivateRoute
                exact
                authenticated={isAuthenticated}
                currentUser={currentUser}
                path="/"
                component={Wallet}
                handleLogout={this.handleLogout}
                handleSessionTimeout={this.handleSessionTimeout}
              />
              <Route
                exact
                path="/forgot-password"
                render={props => (
                  <ForgotPassword
                    isAuthenticated={isAuthenticated}
                    currentUser={currentUser}
                    handleLogout={this.handleLogout}
                    {...props}
                  />
                )}
              />
              <Route
                path="/login"
                render={props => (
                  <Login
                    onLogin={this.handleLogin}
                    currentUser={currentUser}
                    {...props}
                  />
                )}
              />
              <Route
                path="/signup"
                render={props => (
                  <Signup currentUser={currentUser} {...props} />
                )}
              />
              <PrivateRoute
                exact
                authenticated={isAuthenticated}
                currentUser={currentUser}
                path="/forum"
                component={Forum}
                handleLogout={this.handleLogout}
                handleSessionTimeout={this.handleSessionTimeout}
              />
              <PrivateRoute
                exact
                authenticated={isAuthenticated}
                currentUser={currentUser}
                path="/forum/thread/:threadId/comment/:commentId/edit"
                component={EditComment}
                handleLogout={this.handleLogout}
                handleSessionTimeout={this.handleSessionTimeout}
              />
              <PrivateRoute
                exact
                authenticated={isAuthenticated}
                currentUser={currentUser}
                path="/forum/thread/:threadId/comment/new"
                component={NewComment}
                handleLogout={this.handleLogout}
              />
              <PrivateRoute
                exact
                authenticated={isAuthenticated}
                path="/forum/thread/:threadId/edit"
                currentUser={currentUser}
                component={EditThread}
                handleLogout={this.handleLogout}
              />
              <PrivateRoute
                exact
                authenticated={isAuthenticated}
                path="/forum/thread/new"
                currentUser={currentUser}
                component={NewThread}
                handleLogout={this.handleLogout}
              />
              <PrivateRoute
                exact
                authenticated={isAuthenticated}
                path="/forum/thread/:threadId"
                currentUser={currentUser}
                component={Thread}
                handleLogout={this.handleLogout}
              />
              <Route
                exact
                path="/confirm-reset"
                render={props => (
                  <ResetPassword
                    isAuthenticated={isAuthenticated}
                    currentUser={currentUser}
                    handleLogout={this.handleLogout}
                    {...props}
                  />
                )}
              />
              <Route
                exact
                path="/message"
                render={props => (
                  <Message
                    messageTitle="Successfully changed password."
                    messageDescription="Redirecting to Login ..."
                    isAuthenticated={isAuthenticated}
                    currentUser={currentUser}
                    {...props}
                  />
                )}
              />
              <PrivateRoute
                exact
                authenticated={isAuthenticated}
                path="/forum"
                currentUser={currentUser}
                component={Forum}
                handleLogout={this.handleLogout}
              />
              <Route
                exact
                path="/new-password"
                render={props => (
                  <NewPassword
                    isAuthenticated={isAuthenticated}
                    currentUser={currentUser}
                    {...props}
                  />
                )}
              />
              <Route
                exact
                path="/verify-email"
                render={props => (
                  <VerifyEmail
                    isAuthenticated={isAuthenticated}
                    currentUser={currentUser}
                    {...props}
                  />
                )}
              />
              <PrivateRoute
                exact
                authenticated={isAuthenticated}
                path="/wallet/:walletId/transaction/add"
                component={AddTransaction}
                currentUser={currentUser}
                handleLogout={this.handleLogout}
                handleSessionTimeout={this.handleSessionTimeout}
              />
              <PrivateRoute
                exact
                authenticated={isAuthenticated}
                path="/wallet/:walletId/expense/:expenseId/edit"
                component={EditExpense}
                currentUser={currentUser}
                handleLogout={this.handleLogout}
                handleSessionTimeout={this.handleSessionTimeout}
              />
              <PrivateRoute
                exact
                authenticated={isAuthenticated}
                path="/wallet/:walletId/income/:incomeId/edit"
                component={EditIncome}
                currentUser={currentUser}
                handleLogout={this.handleLogout}
                handleSessionTimeout={this.handleSessionTimeout}
              />
              <PrivateRoute
                exact
                authenticated={isAuthenticated}
                currentUser={currentUser}
                path="/wallet/:walletId/transaction"
                component={Transaction}
                handleLogout={this.handleLogout}
                handleSessionTimeout={this.handleSessionTimeout}
              />
              <PrivateRoute
                exact
                authenticated={isAuthenticated}
                currentUser={currentUser}
                path="/wallet/:walletId/edit"
                component={EditWallet}
                handleLogout={this.handleLogout}
                handleSessionTimeout={this.handleSessionTimeout}
              />
              <PrivateRoute
                exact
                authenticated={isAuthenticated}
                currentUser={currentUser}
                path="/wallet/new"
                component={NewWallet}
                handleLogout={this.handleLogout}
              />
              <PrivateRoute
                exact
                authenticated={isAuthenticated}
                currentUser={currentUser}
                path="/edit-password"
                component={EditPassword}
                handleLogout={this.handleLogout}
              />
              <Route component={NotFound} />
            </Switch>
          </div>
        </Content>
      </Layout>
    );
  }
}

export default withRouter(App);
