import { Typography, notification } from "antd";
import React, { Component } from "react";
import LoadingIndicator from "../common/LoadingIndicator";

import { APP_NAME } from "../constants";
import { verifyEmail } from "../util/APIUtils";

const { Title } = Typography;

class VerifyEmail extends Component {
  verifyEmailToken(token) {
    let promise;
    promise = verifyEmail(token);
    if (!promise) {
      return;
    }

    this.setState({
      isLoading: true,
    });
    promise
      .then(response => {
        if (response.success) {
          notification.success({
            message: APP_NAME,
            description: "You have successfully verified your account.",
          });
        } else {
          notification.error({
            message: APP_NAME,
            description: "Invalid token! Token has expired!",
          });
        }
      })
      .catch(error => {
        if (error.status === 401) {
          this.props.handleSessionTimeout();
        } else {
          notification.error({
            message: APP_NAME,
            description: "Sorry! Something went wrong. Please try again!",
          });
        }
        this.setState({
          isLoading: false,
        });
      });
  }

  componentDidMount() {
    const { history: { push } } = this.props;
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let token = params.get("token");
    this.verifyEmailToken(token);
    setTimeout(() => push("/login"), 2000);
  }

  render() {
    return (
      <div className="page-not-found">
        <Title level={2} className="margin0">
          Verifying Email
        </Title>
        <div className="desc">You will be redirected to login.</div>
        <LoadingIndicator />
      </div>
    );
  }
}

export default VerifyEmail;
