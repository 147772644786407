import {
  AMOUNT_MAX_VAL,
  AMOUNT_MIN_VAL,
  DESCRIPTION_MAX_LENGTH,
  DESCRIPTION_MIN_LENGTH,
  EMAIL_MAX_LENGTH,
  EMAIL_REGEX,
  FORUM_COMMENT_DESCRIPTION_MAX_LENGTH,
  FORUM_COMMENT_DESCRIPTION_MIN_LENGTH,
  FORUM_THREAD_DESCRIPTION_MIN_LENGTH,
  FORUM_THREAD_DESCRIPTION_MAX_LENGTH,
  FORUM_THREAD_TITLE_MAX_LENGTH,
  FORUM_THREAD_TITLE_MIN_LENGTH,
  PASSWORD_MAX_LENGTH,
  PASSWORD_MIN_LENGTH,
  PASSWORD_REGEX,
  TRANSACTION_DESCRIPTION_MAX_LENGTH,
  TRANSACTION_DESCRIPTION_MIN_LENGTH,
  USERNAME_MAX_LENGTH,
  USERNAME_MIN_LENGTH,
  USERNAME_REGEX,
  WALLET_NAME_MAX_LENGTH,
  WALLET_NAME_MIN_LENGTH,
  WALLET_NAME_REGEX,
  TRANSACTION_AMOUNT_MIN,
  TRANSACTION_AMOUNT_MAX,
} from "../constants";

export const validateName = name => {
  if (name.length < DESCRIPTION_MIN_LENGTH) {
    return {
      validateStatus: "error",
      errorMsg: `Name is too short (Minimum ${DESCRIPTION_MIN_LENGTH} characters needed.)`,
    };
  } else if (name.length > DESCRIPTION_MAX_LENGTH) {
    return {
      validateStatus: "error",
      errorMsg: `Name is too long (Maximum ${DESCRIPTION_MAX_LENGTH} characters allowed.)`,
    };
  } else {
    return {
      validateStatus: "success",
      errorMsg: null,
    };
  }
};

export const validateTitle = title => {
  if (title.length < FORUM_THREAD_TITLE_MIN_LENGTH) {
    return {
      validateStatus: "error",
      errorMsg: `Title is too short (Minimum ${FORUM_THREAD_TITLE_MIN_LENGTH} characters needed.)`,
    };
  } else if (title.length > FORUM_THREAD_TITLE_MAX_LENGTH) {
    return {
      validateStatus: "error",
      errorMsg: `Title is too long (Maximum ${FORUM_THREAD_TITLE_MAX_LENGTH} characters allowed.)`,
    };
  } else {
    return {
      validateStatus: "success",
      errorMsg: null,
    };
  }
};

export const validateDescription = description => {
  if (description.length < FORUM_THREAD_DESCRIPTION_MIN_LENGTH) {
    return {
      validateStatus: "error",
      errorMsg: `Description is too short (Minimum ${FORUM_THREAD_DESCRIPTION_MIN_LENGTH} characters needed.)`,
    };
  } else if (description.length > FORUM_THREAD_DESCRIPTION_MAX_LENGTH) {
    return {
      validateStatus: "error",
      errorMsg: `Description is too long (Maximum ${FORUM_THREAD_DESCRIPTION_MAX_LENGTH} characters allowed.)`,
    };
  } else {
    return {
      validateStatus: "success",
      errorMsg: null,
    };
  }
};

export const validateCommentDescription = description => {
  if (description.length < FORUM_COMMENT_DESCRIPTION_MIN_LENGTH) {
    return {
      validateStatus: "error",
      errorMsg: `Description is too short (Minimum ${FORUM_COMMENT_DESCRIPTION_MIN_LENGTH} characters needed.)`,
    };
  } else if (description.length > FORUM_COMMENT_DESCRIPTION_MAX_LENGTH) {
    return {
      validateStatus: "error",
      errorMsg: `Description is too long (Maximum ${FORUM_COMMENT_DESCRIPTION_MAX_LENGTH} characters allowed.)`,
    };
  } else {
    return {
      validateStatus: "success",
      errorMsg: null,
    };
  }
};

export const validateEmail = email => {
  if (!email) {
    return {
      validateStatus: "error",
      errorMsg: "Email may not be empty",
    };
  }

  if (!EMAIL_REGEX.test(email)) {
    return {
      validateStatus: "error",
      errorMsg: "Email not valid",
    };
  }

  if (email.length > EMAIL_MAX_LENGTH) {
    return {
      validateStatus: "error",
      errorMsg: `Email is too long (Maximum ${EMAIL_MAX_LENGTH} characters allowed)`,
    };
  }

  return {
    validateStatus: "success",
    errorMsg: null,
  };
};

export const validateWalletName = name => {
  if (!name) {
    return {
      validateStatus: "error",
      errorMsg: "Wallet Name may not be empty",
    };
  }

  if (name.length < WALLET_NAME_MIN_LENGTH) {
    return {
      validateStatus: "error",
      errorMsg: `Wallet Name is too short (Minimum ${WALLET_NAME_MIN_LENGTH} characters needed.)`,
    };
  } else if (name.length > WALLET_NAME_MAX_LENGTH) {
    return {
      validateStatus: "error",
      errorMsg: `Wallet Name is too long (Maximum ${WALLET_NAME_MAX_LENGTH} characters allowed.)`,
    };
  } else if (!WALLET_NAME_REGEX.test(name)) {
    return {
      validateStatus: "error",
      errorMsg: "Wallet Name not valid",
    };
  } else {
    return {
      validateStatus: "success",
      errorMsg: null,
    };
  }
};

export const validateUsername = username => {
  if (username.length < USERNAME_MIN_LENGTH) {
    return {
      validateStatus: "error",
      errorMsg: `Username is too short (Minimum ${USERNAME_MIN_LENGTH} characters needed.)`,
    };
  } else if (username.length > USERNAME_MAX_LENGTH) {
    return {
      validateStatus: "error",
      errorMsg: `Username is too long (Maximum ${USERNAME_MAX_LENGTH} characters allowed.)`,
    };
  } else if (!USERNAME_REGEX.test(username)) {
    return {
      validateStatus: "error",
      errorMsg:
        "Invalid username. Please ensure your username only contain alphanumeric and/or '_'",
    };
  } else {
    return {
      validateStatus: null,
      errorMsg: null,
    };
  }
};

export const validatePassword = password => {
  if (password.length === 0) {
    return {
      validateStatus: "error",
      errorMsg: `Password cannot be empty.`,
    };
  }
  if (!PASSWORD_REGEX.test(password)) {
    return {
      validateStatus: "error",
      errorMsg:
        "Invalid password. Please ensure your password only contain alphanumeric with at least 1 uppercase, 1 lowercase and 1 special character.",
    };
  }
  if (password.length < PASSWORD_MIN_LENGTH) {
    return {
      validateStatus: "error",
      errorMsg: `Password is too short (Minimum ${PASSWORD_MIN_LENGTH} characters needed.)`,
    };
  } else if (password.length > PASSWORD_MAX_LENGTH) {
    return {
      validateStatus: "error",
      errorMsg: `Password is too long (Maximum ${PASSWORD_MAX_LENGTH} characters allowed.)`,
    };
  } else {
    return {
      validateStatus: "success",
      errorMsg: null,
    };
  }
};

export const validateLoginPassword = password => {
  if (password.length === 0) {
    return {
      validateStatus: "error",
      errorMsg: `Password cannot be empty.`,
    };
  } else {
    return {
      validateStatus: "success",
      errorMsg: null,
    };
  }
};

export const validateConfirmPassword = (password, confirmPassword) => {
  if (password !== confirmPassword) {
    return {
      validateStatus: "error",
      errorMsg: `Password and Confirm Password do not match.`,
    };
  } else {
    return {
      validateStatus: "success",
      errorMsg: null,
    };
  }
};

export const validateCaptcha = captcha => {
  if (captcha.length === 0) {
    return {
      validateStatus: "error",
      errorMsg: `Please enter the Captcha.`,
    };
  } else {
    return {
      validateStatus: "success",
      errorMsg: null,
    };
  }
};

export const validateBalance = balance => {
  if (balance < AMOUNT_MIN_VAL) {
    return {
      validateStatus: "error",
      errorMsg: `Balance is too low (Minimum: $${AMOUNT_MIN_VAL})`,
    };
  } else if (balance > AMOUNT_MAX_VAL) {
    return {
      validateStatus: "error",
      errorMsg: `Balance is too high (Maximum: $${AMOUNT_MAX_VAL})`,
    };
  } else {
    return {
      validateStatus: "success",
      errorMsg: null,
    };
  }
};

export const validateTransactionDescription = description => {
  if (description.length < TRANSACTION_DESCRIPTION_MIN_LENGTH) {
    return {
      validateStatus: "error",
      errorMsg: `Description is too short (Minimum ${TRANSACTION_DESCRIPTION_MIN_LENGTH} characters needed.)`,
    };
  } else if (description.length > TRANSACTION_DESCRIPTION_MAX_LENGTH) {
    return {
      validateStatus: "error",
      errorMsg: `Description is too long (Maximum ${TRANSACTION_DESCRIPTION_MAX_LENGTH} characters allowed.)`,
    };
  } else {
    return {
      validateStatus: "success",
      errorMsg: null,
    };
  }
};

export const validateTransactionAmount = amount => {
  if (amount < TRANSACTION_AMOUNT_MIN) {
    return {
      validateStatus: "error",
      errorMsg: `Amount is too low (Minimum: $${TRANSACTION_AMOUNT_MIN})`,
    };
  } else if (amount > TRANSACTION_AMOUNT_MAX) {
    return {
      validateStatus: "error",
      errorMsg: `Amount is too high (Maximum: $${TRANSACTION_AMOUNT_MAX})`,
    };
  } else {
    return {
      validateStatus: "success",
      errorMsg: null,
    };
  }
};
