import { Typography, Row, Card, Col, Button, Icon } from "antd";
import React, { Component } from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import LoadingIndicator from "../common/LoadingIndicator";
import { getThreads } from "../util/APIUtils";
import "./Forum.css";

const { Title } = Typography;

class Forum extends Component {
  constructor(props) {
    super(props);
    this.state = {
      threads: [],
      isLoading: false,
    };
  }

  loadThreads() {
    const { currentUser } = this.props;
    let promise;
    if (currentUser && currentUser.username) {
      promise = getThreads();
    }
    if (!promise) {
      return;
    }

    this.setState({
      isLoading: true,
    });
    promise
      .then(response => {
        const threads = this.state.threads.slice();
        this.setState({
          threads: threads.concat(response),
          isLoading: false,
        });
      })
      .catch(error => {
        if (error.status === 401) {
          this.props.handleSessionTimeout();
        }
        this.setState({
          isLoading: false,
        });
      });
  }

  componentDidMount() {
    this.loadThreads();
  }

  render() {
    const { threads } = this.state;

    return this.state.isLoading ? (
      <LoadingIndicator />
    ) : (
      <div className="thread-container">
        <Row>
          <Col span={20}>
            <Title>Forum</Title>
          </Col>
          <Col span={2}>
            <Link to="/forum/thread/new">
              <Button>
                <Icon type="plus" /> Ask Question
              </Button>
            </Link>
          </Col>
        </Row>
        {threads.length !== 0 ? (
          threads.map((thread, threadIndex) => (
            <Row key={threadIndex} className="thread-wrapper">
              <Card
                style={{ width: "100%" }}
                bodyStyle={{ padding: "8px 18px" }}
              >
                <Col span={21} style={{ verticalAlign: "middle" }}>
                  <div className="title">
                    <Link to={`/forum/thread/${thread.postId}`}>
                      {thread.title}
                    </Link>
                  </div>
                  <div className="username">
                    {thread.userAccount.username} asked{" "}
                    <Moment fromNow>{thread.createdOn}</Moment> • Questions
                  </div>
                </Col>
                <Col offset={1} span={2}>
                  <Card bodyStyle={{ padding: "4px", textAlign: "center" }}>
                    <div className="no-of-comments">{thread.noOfComments}</div>
                    <div className="replies-label">Replies</div>
                  </Card>
                </Col>
              </Card>
            </Row>
          ))
        ) : (
          <Col span={24} className="no-questions-found-placeholder">
            <Title level={4}>No questions found.</Title>
          </Col>
        )}
      </div>
    );
  }
}

export default Forum;
