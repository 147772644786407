import { Form, Input, Button, notification } from "antd";
import React, { Component } from "react";
import LoadingIndicator from "../common/LoadingIndicator";
import { APP_NAME } from "../constants";
import { addComment } from "../util/APIUtils";
import { validateCommentDescription } from "../util/Validators";

const FormItem = Form.Item;

class NewComment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      description: {
        value: "",
      },
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.isFormInvalid = this.isFormInvalid.bind(this);
  }

  handleInputChange(event, validationFun) {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;

    this.setState({
      [inputName]: {
        value: inputValue,
        ...validationFun(inputValue),
      },
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    const { threadId } = this.state;

    const commentRequest = {
      content: this.state.description.value,
    };
    addComment(commentRequest, threadId)
      .then(response => {
        if (response.success) {
          notification.success({
            message: APP_NAME,
            description:
              "Thank you! You have successfully submitted a comment.",
          });
          this.props.history.push("/forum/thread/" + threadId);
        } else {
          notification.error({
            message: APP_NAME,
            description:
              response.message ||
              "Sorry! Something went wrong. Please try again!",
          });
        }
      })
      .catch(error => {
        notification.error({
          message: APP_NAME,
          description: "Sorry! Something went wrong. Please try again!",
        });
      });
  }

  isFormInvalid() {
    return this.state.description.validateStatus !== "success";
  }

  componentDidMount() {
    const { match } = this.props;
    const threadId = match.params.threadId;
    this.setState({
      threadId,
    });
  }

  render() {
    return this.state.isLoading ? (
      <LoadingIndicator />
    ) : (
      <div className="signup-container">
        <h1 className="page-title">Submit a Comment</h1>
        <div className="signup-content">
          <Form onSubmit={this.handleSubmit} className="signup-form">
            <FormItem
              required
              label="Comment"
              validateStatus={this.state.description.validateStatus}
              help={this.state.description.errorMsg}
            >
              <Input
                required
                size="large"
                name="description"
                autoComplete="off"
                placeholder="Comment"
                value={this.state.description.value}
                onChange={event =>
                  this.handleInputChange(event, validateCommentDescription)}
              />
            </FormItem>
            <FormItem>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                className="signup-form-button"
                disabled={this.isFormInvalid()}
              >
                Submit Comment
              </Button>
            </FormItem>
          </Form>
        </div>
      </div>
    );
  }
}

export default NewComment;
