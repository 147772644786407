import { Form, Input, Button, notification } from "antd";
import React, { Component } from "react";
import LoadingIndicator from "../common/LoadingIndicator";
import { APP_NAME } from "../constants";
import { editWallet, getWallet } from "../util/APIUtils";
import { validateWalletName } from "../util/Validators";

const FormItem = Form.Item;

class EditWallet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: {
        value: "",
      },
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.isFormInvalid = this.isFormInvalid.bind(this);
  }

  handleInputChange(event, validationFun) {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;

    this.setState({
      [inputName]: {
        value: inputValue,
        ...validationFun(inputValue),
      },
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    const { match } = this.props;
    const walletId = match.params.walletId;
    editWallet(walletId, this.state.name.value)
      .then(response => {
        if (response.success) {
          notification.success({
            message: APP_NAME,
            description: "Thank you! You have successfully edited a wallet.",
          });
          this.props.history.push("/");
        } else {
          notification.error({
            message: APP_NAME,
            description:
              response.message ||
              "Sorry! Something went wrong. Please try again!",
          });
        }
      })
      .catch(error => {
        notification.error({
          message: APP_NAME,
          description:
            error.message || "Sorry! Something went wrong. Please try again!",
        });
      });
  }

  isFormInvalid() {
    return this.state.name.validateStatus !== "success";
  }

  loadWallet(walletId) {
    const { currentUser } = this.props;
    let promise;
    if (currentUser && currentUser.username) {
      promise = getWallet(walletId);
    }

    if (!promise) {
      return;
    }

    this.setState({
      isLoading: true,
    });

    promise
      .then(response => {
        if (response.name) {
          this.setState({
            name: {
              value: response.name,
            },
            isLoading: false,
          });
        } else {
          notification.error({
            message: APP_NAME,
            description: "Sorry! Something went wrong. Please try again!",
          });
          this.props.history.push("/");
        }
      })
      .catch(error => {
        if (error.status === 401) {
          this.props.handleSessionTimeout();
        } else {
          notification.error({
            message: APP_NAME,
            description: "Sorry! Something went wrong. Please try again!",
          });
          this.props.history.push("/");
        }
        this.setState({
          isLoading: false,
        });
      });
  }

  componentDidMount() {
    const { match } = this.props;
    const walletId = match.params.walletId;
    this.setState({
      walletId,
    });
    this.loadWallet(walletId);
  }

  render() {
    const { name } = this.state;
    return this.state.isLoading ? (
      <LoadingIndicator />
    ) : (
      <div className="signup-container">
        <h1 className="page-title">Edit Wallet Name</h1>
        <div className="signup-content">
          <Form onSubmit={this.handleSubmit} className="signup-form">
            <FormItem
              required
              label="Name"
              validateStatus={name.validateStatus}
              help={name.errorMsg}
            >
              <Input
                required
                size="large"
                name="name"
                autoComplete="off"
                placeholder="Name"
                value={name.value}
                onChange={event =>
                  this.handleInputChange(event, validateWalletName)}
              />
            </FormItem>
            <FormItem>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                className="signup-form-button"
                disabled={this.isFormInvalid()}
              >
                Edit
              </Button>
            </FormItem>
          </Form>
        </div>
      </div>
    );
  }
}

export default EditWallet;
