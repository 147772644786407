import {
  notification,
  Col,
  Row,
  Typography,
  Icon,
  Card,
  Button,
  Popconfirm,
} from "antd";
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import LoadingIndicator from "../common/LoadingIndicator";
import { APP_NAME } from "../constants";
import { getUserWallets, deleteWallet } from "../util/APIUtils";
import "./Wallet.css";

const { Title } = Typography;

class Wallet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      banks: [],
      wallets: [],
      page: 0,
      size: 10,
      totalElements: 0,
      totalPages: 0,
      last: true,
      currentVotes: [],
      isLoading: false,
    };

    this.deleteWalletHandler = this.deleteWalletHandler.bind(this);
  }

  loadWalletList() {
    const { currentUser } = this.props;
    let promise;
    if (currentUser && currentUser.username) {
      promise = getUserWallets();
    }
    if (!promise) {
      return;
    }

    this.setState({
      isLoading: true,
    });
    promise
      .then(response => {
        const wallets = this.state.wallets.slice();
        this.setState({
          wallets: wallets.concat(response),
          isLoading: false,
        });
      })
      .catch(error => {
        if (error.status === 401) {
          this.props.handleSessionTimeout();
        }
        this.setState({
          isLoading: false,
        });
      });
  }

  deleteWalletHandler(transactionId) {
    const { currentUser } = this.props;
    let promise;
    if (currentUser && currentUser.username) {
      deleteWallet(transactionId)
        .then(response => {
          notification.success({
            message: APP_NAME,
            description: "You have successfully deleted a wallet",
          });
          setTimeout(() => window.location.reload(), 500);
        })
        .catch(error => {
          notification.error({
            message: APP_NAME,
            description:
              error.message || "Sorry! Something went wrong. Please try again!",
          });
        });
    }
    if (!promise) {
      return;
    }
  }

  componentDidMount() {
    this.loadWalletList();
  }

  componentDidUpdate(nextProps) {
    if (this.props.isAuthenticated !== nextProps.isAuthenticated) {
      // Reset State
      this.setState({
        wallets: [],
        isLoading: false,
      });
      this.loadWalletList();
    }
  }

  handleChange = (filters, sorter) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  render() {
    const { wallets } = this.state;
    return this.state.isLoading ? (
      <LoadingIndicator />
    ) : (
      <div className="mt32">
        <Row>
          <Col span={12}>
            <Title level={3}>Wallets</Title>
          </Col>
          <Col span={12} className="textRight">
            <Link to="/wallet/new">
              <Button>
                <Icon type="wallet" /> New Bank / Wallet
              </Button>
            </Link>
          </Col>
        </Row>
        <Row gutter={16}>
          {wallets.length !== 0 ? (
            wallets.map((wallet, walletIndex) => (
              <Col span={6} key={walletIndex}>
                <Link to={`/wallet/${wallet.walletId}/transaction`}>
                  <Card
                    className="account"
                    title={wallet.name}
                    actions={[
                      <Link to={`/wallet/${wallet.walletId}/transaction`}>
                        <Icon type="select" key="select" />
                      </Link>,
                      <Link to={`/wallet/${wallet.walletId}/edit`}>
                        <Icon type="edit" key="edit" />
                      </Link>,
                      <Popconfirm
                        title="Are you sure？"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() =>
                          this.deleteWalletHandler(wallet.walletId)}
                      >
                        <Icon type="delete" key="delete" />
                      </Popconfirm>,
                    ]}
                  >
                    <Title level={4} className="margin0">
                      SGD {wallet.balance}
                    </Title>
                  </Card>
                </Link>
              </Col>
            ))
          ) : (
            <Col span={24} style={{ textAlign: "center", margin: "128px 0" }}>
              <Title level={4}>No wallet found.</Title>
            </Col>
          )}
        </Row>
      </div>
    );
  }
}

export default withRouter(Wallet);
