import { Form, Input, Button, notification } from "antd";
import React, { Component } from "react";
import LoadingIndicator from "../common/LoadingIndicator";
import { APP_NAME } from "../constants";

import { editThread, getThreadById } from "../util/APIUtils";
import { validateDescription, validateTitle } from "../util/Validators";

const FormItem = Form.Item;

class EditThread extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: {
        value: "",
      },
      description: {
        value: "",
      },
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.isFormInvalid = this.isFormInvalid.bind(this);
  }

  handleInputChange(event, validationFun) {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;

    this.setState({
      [inputName]: {
        value: inputValue,
        ...validationFun(inputValue),
      },
    });
  }

  checkLoggedIn() {
    const { currentUser } = this.props;

    if (!currentUser) {
      this.props.history.push("/login");
    }
  }

  handleSubmit(event) {
    event.preventDefault();

    const threadRequest = {
      title: this.state.title.value,
      description: this.state.description.value,
    };
    editThread(threadRequest, this.state.threadId)
      .then(response => {
        if (response.success) {
          notification.success({
            message: APP_NAME,
            description: "Thank you! You have successfully edited a question.",
          });
          this.props.history.push("/forum");
        } else {
          notification.error({
            message: APP_NAME,
            description:
              response.message ||
              "Sorry! Something went wrong. Please try again!",
          });
        }
      })
      .catch(error => {
        notification.error({
          message: APP_NAME,
          description:
            error.message || "Sorry! Something went wrong. Please try again!",
        });
      });
  }

  isFormInvalid() {
    return !(
      this.state.title.validateStatus === "success" &&
      this.state.description.validateStatus === "success"
    );
  }

  loadThread(threadId) {
    const { currentUser } = this.props;
    let promise;
    if (currentUser && currentUser.username) {
      promise = getThreadById(threadId);
    }
    if (!promise) {
      return;
    }

    this.setState({
      isLoading: true,
    });
    promise
      .then(response => {
        if (response !== null) {
          this.setState({
            title: { value: response.title, validateStatus: "success" },
            description: {
              value: response.description,
              validateStatus: "success",
            },
            isLoading: false,
          });
        } else {
          notification.error({
            message: APP_NAME,
            description: "Sorry! Something went wrong. Please try again!",
          });
          this.props.history.push("/forum");
        }
      })
      .catch(error => {
        if (error.status === 401) {
          this.props.handleSessionTimeout();
        } else {
          notification.error({
            message: APP_NAME,
            description: "Sorry! Something went wrong. Please try again!",
          });
          this.props.history.push("/forum");
        }
        this.setState({
          isLoading: false,
        });
      });
  }

  componentDidMount() {
    const { match } = this.props;
    const threadId = match.params.threadId;
    this.setState({
      threadId,
    });
    this.checkLoggedIn();
    this.loadThread(threadId);
  }

  render() {
    return this.state.isLoading ? (
      <LoadingIndicator />
    ) : (
      <div className="signup-container">
        <h1 className="page-title">Edit a Question</h1>
        <div className="signup-content">
          <Form onSubmit={this.handleSubmit} className="signup-form">
            <FormItem
              label="Title"
              validateStatus={this.state.title.validateStatus}
              help={this.state.title.errorMsg}
              required
            >
              <Input
                required
                size="large"
                name="title"
                autoComplete="off"
                placeholder="Title"
                value={this.state.title.value}
                onChange={event => this.handleInputChange(event, validateTitle)}
              />
            </FormItem>
            <FormItem
              required
              label="Description"
              validateStatus={this.state.description.validateStatus}
              help={this.state.description.errorMsg}
            >
              <Input
                required
                size="large"
                name="description"
                autoComplete="off"
                placeholder="Description"
                value={this.state.description.value}
                onChange={event =>
                  this.handleInputChange(event, validateDescription)}
              />
            </FormItem>
            <FormItem>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                className="signup-form-button"
                disabled={this.isFormInvalid()}
              >
                Update Question
              </Button>
            </FormItem>
          </Form>
        </div>
      </div>
    );
  }
}

export default EditThread;
