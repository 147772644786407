import { Form, Input, Button, notification } from "antd";
import React, { Component } from "react";
import LoadingIndicator from "../common/LoadingIndicator";
import { APP_NAME } from "../constants";
import { editComment, getCommentById } from "../util/APIUtils";
import { validateCommentDescription } from "../util/Validators";

const FormItem = Form.Item;

class EditComment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      description: {
        value: "",
      },
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.isFormInvalid = this.isFormInvalid.bind(this);
  }

  handleInputChange(event, validationFun) {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;

    this.setState({
      [inputName]: {
        value: inputValue,
        ...validationFun(inputValue),
      },
    });
  }

  loadComment(commentId) {
    const { currentUser } = this.props;
    let promise;
    if (currentUser && currentUser.username) {
      promise = getCommentById(commentId);
    }
    if (!promise) {
      return;
    }

    this.setState({
      isLoading: true,
    });
    promise
      .then(response => {
        if (response !== null) {
          this.setState({
            description: {
              value: response.content,
              validateStatus: "success",
            },
            isLoading: false,
          });
        } else {
          notification.error({
            message: APP_NAME,
            description: "Sorry! Something went wrong. Please try again!",
          });
          this.props.history.push("/forum");
        }
      })
      .catch(error => {
        if (error.status === 401) {
          this.props.handleSessionTimeout();
        } else {
          notification.error({
            message: APP_NAME,
            description: "Sorry! Something went wrong. Please try again!",
          });
          this.props.history.push("/forum");
        }
        this.setState({
          isLoading: false,
        });
      });
  }

  handleSubmit(event) {
    event.preventDefault();
    const { commentId, threadId } = this.state;

    const commentRequest = {
      content: this.state.description.value,
    };
    editComment(commentRequest, commentId)
      .then(response => {
        if (response.success) {
          notification.success({
            message: APP_NAME,
            description: "Thank you! You have successfully edited a comment.",
          });
          this.props.history.push("/forum/thread/" + threadId);
        } else {
          notification.error({
            message: APP_NAME,
            description:
              response.message ||
              "Sorry! Something went wrong. Please try again!",
          });
        }
      })
      .catch(error => {
        notification.error({
          message: APP_NAME,
          description:
            error.message || "Sorry! Something went wrong. Please try again!",
        });
      });
  }

  isFormInvalid() {
    return this.state.description.validateStatus !== "success";
  }

  checkLoggedIn() {
    const { currentUser } = this.props;

    if (!currentUser) {
      this.props.history.push("/login");
    }
  }

  componentDidMount() {
    const { match } = this.props;
    const threadId = match.params.threadId;
    const commentId = match.params.commentId;
    this.setState({
      commentId,
      threadId,
    });
    this.checkLoggedIn();
    this.loadComment(commentId);
  }

  render() {
    return this.state.isLoading ? (
      <LoadingIndicator />
    ) : (
      <div className="signup-container">
        <h1 className="page-title">Edit a Comment</h1>
        <div className="signup-content">
          <Form onSubmit={this.handleSubmit} className="signup-form">
            <FormItem
              required
              label="Comment"
              validateStatus={this.state.description.validateStatus}
              help={this.state.description.errorMsg}
            >
              <Input
                required
                size="large"
                name="description"
                autoComplete="off"
                placeholder="Comment"
                value={this.state.description.value}
                onChange={event =>
                  this.handleInputChange(event, validateCommentDescription)}
              />
            </FormItem>
            <FormItem>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                className="signup-form-button"
                disabled={this.isFormInvalid()}
              >
                Update Comment
              </Button>
            </FormItem>
          </Form>
        </div>
      </div>
    );
  }
}

export default EditComment;
